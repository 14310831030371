import { useSelector } from 'react-redux'

import {
  type FeatureFlagKey,
  FeatureFlagService,
} from '@webapp/core/services/FeatureFlag'
import { makeSelectFeatureFlag } from '@webapp/core/stores/global'

export const useFeatureFlag = (featureFlagKey: FeatureFlagKey): boolean => {
  const fallbackValue = !!useSelector(makeSelectFeatureFlag(featureFlagKey))

  try {
    const featureFlagService = FeatureFlagService.getInstance()
    return featureFlagService.getFlag(featureFlagKey)
  } catch (e) {
    return fallbackValue
  }
}
