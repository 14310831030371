import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const CardholderAccountPageChunk = React.lazy(() =>
  import('../feature-sets/SpendingCardCardholderAccountView').then(
    ({ CardholderAccountView }) => ({
      default: CardholderAccountView,
    })
  )
)

export const CardholderAccountPage = () => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.cardholderAccountPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.cardholderAccountPageTitle),
        },
      ]}
    >
      <CardholderAccountPageChunk />
    </Page>
  )
}
