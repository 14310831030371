import { defineMessages } from 'react-intl'

const scope = `procurify.spending-cards.page`

export default defineMessages({
  cardholderAccountPageTitle: {
    id: `${scope}.cardholderAccountPageTitle`,
    defaultMessage: 'Cardholder account',
  },
})
