import { type Enum } from '@webapp/core/types/Enum'

export const CreditCardVendors = {
  OTHER: 0,
  VISA: 1,
  MASTERCARD: 2,
  AMERICAN_EXPRESS: 3,
  DISCOVER: 4,
  JCB: 5,
  DINERS_CLUB: 6,
  PROCURIFY: 7,
} as const

export type CreditCardVendorEnum = Enum<typeof CreditCardVendors>
