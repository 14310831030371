import { useEntitlement } from '@webapp/core/hooks/useEntitlement'

export interface AppModuleConfig {
  userAccountNotifications: {
    showSpendingCardNotifications: boolean
    showContractNotifications: boolean
    showPurchasingNotifications: boolean
    showAPNotifications: boolean
    showExpenseNotifications: boolean
  }
  bills: {
    enableBillCreate: boolean
  }
  navigation: {
    showExpenseNavigation: boolean
    showSpendingCardNavigation: boolean
    showPurchasingNavigation: boolean
    showExpenseApNavigation: boolean
    showEverythingButExpenseApNavigation: boolean
    showAnalyticsNavigation: boolean
  }
  routes: {
    enablePurchasingRoutes: boolean
    enableExpenseApRoutes: boolean
    enableExpenseRoutes: boolean
    enableEverythingButExpenseApRoutes: boolean
    enableBudgetRoutes: boolean
    enableContractRoutes: boolean
    enableSpendingCardRoutes: boolean
    enableDataAndInsightsRoute: boolean
  }
  quickRequestButton: {
    enableOrderButton: boolean
    enableExpenseButton: boolean
    enableTravelButton: boolean
    enableFundButton: boolean
    enableVirtualCardButton: boolean
    enableExpenseBillButton: boolean
  }
  approvalRouting: {
    enableVendorTab: boolean
    enablePaymentsTab: boolean
    enableRFOTriggerOption: boolean
  }
  settings: {
    enableCustomizeExpenseForm: boolean
    enableCustomizeRequestForOrderForm: boolean
    enableCustomizePurchaseOrderForm: boolean
    enableCatalogItemAccess: boolean
    enableCustomizeContracts: boolean
    enablePunchoutSuppliers: boolean
    enableCustomizeUnitType: boolean
    enableCustomizeAPForm: boolean
    enablePurchaseAgreements: boolean
    enableVendorPerformance: boolean
    enableSpendingCardCustomFields: boolean
    enableManageBudgets: boolean
    enableManageFinancialAccount: boolean
    enableManageBankAccounts: boolean
    enablePunchout: boolean
    enableAlternateAccountCodeCorrectionDescription: boolean
    enableAlternateTaxTypesDescription: boolean
    enableAlternateClosingMonthsDescription: boolean
  }
  importer: {
    showVendorAndOrderImporters: boolean
    showBudgetImporters: boolean
    showOrderInfoInApprovalGroupImporterDescription: boolean
  }
  spendingCardApproval: {
    showBudgets: boolean
  }
}

const DEFAULT_MODULE_CONFIG: AppModuleConfig = {
  userAccountNotifications: {
    showSpendingCardNotifications: false,
    showContractNotifications: false,
    showPurchasingNotifications: false,
    showAPNotifications: false,
    showExpenseNotifications: false,
  },
  bills: {
    enableBillCreate: false,
  },
  navigation: {
    showExpenseNavigation: false,
    showSpendingCardNavigation: false,
    showPurchasingNavigation: false,
    showExpenseApNavigation: false,
    showEverythingButExpenseApNavigation: false,
    showAnalyticsNavigation: false,
  },
  routes: {
    enablePurchasingRoutes: false,
    enableExpenseApRoutes: false,
    enableExpenseRoutes: false,
    enableEverythingButExpenseApRoutes: false,
    enableBudgetRoutes: false,
    enableContractRoutes: false,
    enableSpendingCardRoutes: false,
    enableDataAndInsightsRoute: false,
  },
  quickRequestButton: {
    enableOrderButton: false,
    enableExpenseButton: false,
    enableTravelButton: false,
    enableFundButton: false,
    enableVirtualCardButton: false,
    enableExpenseBillButton: false,
  },
  approvalRouting: {
    enableVendorTab: false,
    enablePaymentsTab: false,
    enableRFOTriggerOption: false,
  },
  settings: {
    enableCustomizeExpenseForm: false,
    enableCustomizeUnitType: false,
    enableCustomizeAPForm: false,
    enableCustomizeContracts: false,
    enableCustomizePurchaseOrderForm: false,
    enableCustomizeRequestForOrderForm: false,
    enableCatalogItemAccess: false,
    enablePunchoutSuppliers: false,
    enablePurchaseAgreements: false,
    enableVendorPerformance: false,
    enableSpendingCardCustomFields: false,
    enableManageBudgets: false,
    enableManageFinancialAccount: false,
    enableManageBankAccounts: false,
    enablePunchout: false,
    enableAlternateAccountCodeCorrectionDescription: false,
    enableAlternateTaxTypesDescription: false,
    enableAlternateClosingMonthsDescription: false,
  },
  importer: {
    showVendorAndOrderImporters: false,
    showBudgetImporters: false,
    showOrderInfoInApprovalGroupImporterDescription: false,
  },
  spendingCardApproval: {
    showBudgets: false,
  },
}

export const useModuleConfig = (): AppModuleConfig => {
  const entitlement = useEntitlement()

  if (!entitlement) {
    return DEFAULT_MODULE_CONFIG
  }

  return {
    userAccountNotifications: {
      showSpendingCardNotifications: entitlement.module.spendingCard.enabled,
      showContractNotifications: entitlement.module.contracts.enabled,
      showPurchasingNotifications: entitlement.module.purchasing.enabled,
      showAPNotifications: entitlement.module.ap.everythingButExpense.enabled,
      showExpenseNotifications: entitlement.module.expense.enabled,
    },
    bills: {
      enableBillCreate: entitlement.module.ap.everythingButExpense.enabled,
    },
    navigation: {
      showExpenseNavigation: entitlement.module.expense.enabled,
      showSpendingCardNavigation: entitlement.module.spendingCard.enabled,
      showPurchasingNavigation: entitlement.module.purchasing.enabled,
      showExpenseApNavigation: entitlement.module.ap.expenseAp.enabled,
      showEverythingButExpenseApNavigation:
        entitlement.module.ap.everythingButExpense.enabled,
      showAnalyticsNavigation:
        entitlement.module.expense.enabled &&
        entitlement.module.purchasing.enabled,
    },
    routes: {
      enablePurchasingRoutes: entitlement.module.purchasing.enabled,
      enableExpenseApRoutes: entitlement.module.ap.expenseAp.enabled,
      enableExpenseRoutes: entitlement.module.expense.enabled,
      enableEverythingButExpenseApRoutes:
        entitlement.module.ap.everythingButExpense.enabled,
      enableBudgetRoutes: entitlement.module.budgets.enabled,
      enableContractRoutes: entitlement.module.contracts.enabled,
      enableSpendingCardRoutes: entitlement.module.spendingCard.enabled,
      // currently having purchasing and expense entitlements equates to having access to the whole app
      enableDataAndInsightsRoute:
        entitlement.module.expense.enabled &&
        entitlement.module.purchasing.enabled,
    },
    quickRequestButton: {
      enableOrderButton: entitlement.module.purchasing.enabled,
      enableExpenseButton: entitlement.module.expense.enabled,
      enableTravelButton: entitlement.module.expense.enabled,
      enableFundButton: entitlement.module.spendingCard.enabled,
      enableVirtualCardButton: entitlement.module.spendingCard.enabled,
      enableExpenseBillButton:
        entitlement.module.ap.expenseAp.enabled &&
        !entitlement.module.ap.everythingButExpense.enabled,
    },
    approvalRouting: {
      enableVendorTab: entitlement.module.purchasing.enabled,
      enablePaymentsTab: entitlement.module.purchasing.enabled,
      enableRFOTriggerOption: entitlement.module.purchasing.enabled,
    },
    settings: {
      enableCustomizeExpenseForm: entitlement.module.expense.enabled,
      enableCustomizeRequestForOrderForm: entitlement.module.purchasing.enabled,
      enableCustomizePurchaseOrderForm: entitlement.module.purchasing.enabled,
      enableCatalogItemAccess: entitlement.module.purchasing.enabled,
      enableCustomizeUnitType: entitlement.module.purchasing.enabled,
      enableCustomizeAPForm: entitlement.module.ap.everythingButExpense.enabled,
      enableCustomizeContracts: entitlement.module.contracts.enabled,
      enablePunchoutSuppliers: entitlement.module.purchasing.enabled,
      enablePurchaseAgreements: entitlement.module.purchasing.enabled,
      enableVendorPerformance: entitlement.module.purchasing.enabled,
      enableSpendingCardCustomFields: entitlement.module.spendingCard.enabled,
      enableManageBudgets: entitlement.module.budgets.enabled,
      enableManageFinancialAccount:
        entitlement.module.ap.everythingButExpense.enabled ||
        entitlement.module.spendingCard.enabled,
      enableManageBankAccounts:
        entitlement.module.ap.everythingButExpense.enabled,
      enablePunchout: entitlement.module.purchasing.enabled,
      enableAlternateAccountCodeCorrectionDescription:
        entitlement.module.ap.expenseAp.enabled &&
        !entitlement.module.ap.everythingButExpense.enabled,
      enableAlternateTaxTypesDescription:
        entitlement.module.ap.expenseAp.enabled &&
        !entitlement.module.ap.everythingButExpense.enabled,
      enableAlternateClosingMonthsDescription:
        entitlement.module.ap.expenseAp.enabled &&
        !entitlement.module.ap.everythingButExpense.enabled,
    },
    importer: {
      showVendorAndOrderImporters: entitlement.module.purchasing.enabled,
      showBudgetImporters: entitlement.module.budgets.enabled,
      showOrderInfoInApprovalGroupImporterDescription:
        entitlement.module.purchasing.enabled,
    },
    spendingCardApproval: {
      showBudgets: entitlement.module.budgets.enabled,
    },
  }
}
