import * as Factory from 'factory.ts'
import faker from 'faker'

import { EntityFactory } from '@webapp/core/utils/mocks/EntityFactory'
import { SpendingCard, type SpendingCardConstructor } from './SpendingCard'
import { CreditCardVendors } from '../../../../core/entities/external/CreditCardVendor/CreditCardVendorEnum'

export const SpendingCardConstructorFactory =
  Factory.Sync.makeFactory<SpendingCardConstructor>({
    id: Factory.each(() => faker.random.number()),
    vendor: CreditCardVendors.MASTERCARD,
    last4Digits: Factory.each(() =>
      faker.random.number({ min: 1000, max: 9999 }).toString()
    ),
    name: Factory.each(() => faker.company.companyName()),
  })

export const SpendingCardFactory = EntityFactory(
  SpendingCard,
  SpendingCardConstructorFactory
)
