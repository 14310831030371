import { type CreditCardVendorEnum } from '@webapp/core/entities/external/CreditCardVendor'

export interface SpendingCardConstructor {
  id: number
  vendor: CreditCardVendorEnum
  last4Digits: string
  name: string
}

export class SpendingCard {
  id: number
  vendor: CreditCardVendorEnum
  last4Digits: string
  name: string

  constructor(data: SpendingCardConstructor) {
    this.id = data.id
    this.vendor = data.vendor
    this.last4Digits = data.last4Digits
    this.name = data.name
  }
}
